<template>
    <div class="rounded-lg" @click="$refs.refprofilphoto.click()">
        <slot></slot>
        <input ref="refprofilphoto" class="ml-auto" type="file" accept=".svg,.jpeg,.png,.jpg,GIF" @change="onFileChange"
            :hidden="true" />
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiCamera, mdiSend, } from '@mdi/js'

// var md5 = require("md5")
export default {
    data() {
        return {
            inputmessage: '',
            mfiles: null,
            image: '',
        };
    },
    created() {
        // this.initialize()
    },
    methods: {
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            this.$emit('showfile', {file: files[0]})
        },
        sendMessageBlog() { },
    },
    setup() {
        const icons = { mdiMagnify, mdiCamera, mdiSend }
        return {
            icons,
        }
    },
}
</script>

<style lang="scss" scoped>
</style>